* {
  font-size: 100%;
  font-family: Poppins;
  color: white;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: #141414;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

a {
  color: white;
  text-decoration: none;
}
